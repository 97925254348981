<script>
import Layout from "../../layouts/main";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      "New Address": "Nova carteira",
      Address: "Endereço",
      "Address is required.": "Endereço é obrigatório.",
      Send: "Cadastrar",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.",
      "We sent an email to confirm your new Metamask/TrustWallet address.":
        "Enviamos um e-mail para confirmar seu novo endereço de Metamask/TrustWallet.",
    },
    es: {
      "New Address": "Nueva billetera",
      Address: "Habla a",
      "Address is required.": "Address is required",
      Send: "Registrar",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Lo sentimos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "We sent an email to confirm your new Metamask/TrustWallet address.":
        "Enviamos un correo electrónico para confirmar su nueva dirección de Metamask/TrustWallet.",
    },
  },
  components: { Layout, VclList },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      table: {
        metamask: {
          heade: ["Date", "Address", "Status"],
          body: null,
          loading: true,
          errored: false,
          empty: false,
        },
      },

      alert: {
        metamask: { type: "", message: "" },
        metamaskModal: { type: "", message: "" },
      },

      metamask: { modal: false, default: null, address: "" },
    };
  },
  validations: {
    metamask: {
      address: { required },
    },
  },
  methods: {
    getMetaMask() {
      this.table.metamask.body = null;

      this.table.metamask.loading = true;
      this.table.metamask.errored = false;
      this.table.metamask.empty = false;

      api
        .get("wallet/metamask")
        .then((response) => {
          if (response.data.status == "success") {
            this.metamask.default = response.data.default;
            this.table.metamask.body = response.data.list;
          } else {
            this.table.metamask.body = null;
          }
        })
        .catch((error) => {
          this.table.metamask.errored = error;
        })
        .finally(() => {
          this.table.metamask.loading = false;
          if (
            this.table.metamask.body == "" ||
            this.table.metamask.body == null
          ) {
            this.table.metamask.empty = true;
          }
        });
    },
    metamaskSubmit() {
      this.$v.metamask.$touch();

      if (this.metamask.address) {
        api
          .post("wallet/metamask", {
            address: this.metamask.address,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.metamask.address = "";
              this.$v.metamask.$reset();

              this.alert.metamask.type = "alert-success";
              this.alert.metamask.message = response.data.message;

              this.metamask.modal = false;
              this.metamask.loading = true;
              this.getMetaMask();
            } else {
              this.alert.metamaskModal.type = "alert-danger";
              this.alert.metamaskModal.message = response.data.message;
            }
          });
      }
    },
  },
  mounted() {
    this.getMetaMask();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Metamask/TrustWallet") }}</h4>
          <div class="page-title-right">
            <button
              type="button"
              class="btn btn-default btn-sm text-uppercase"
              @click="metamask.modal = true"
            >
              {{ t("New Address") }}
            </button>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div
              v-if="alert.metamask.message"
              :class="'alert ' + alert.metamask.type"
            >
              {{ t(alert.metamask.message) }}
            </div>
            <div v-if="metamask.default" class="pt-3 pb-0 pl-3 p-r">
              <h4 class="card-title mb-0">{{ t("Address") }}</h4>
              <p class="card-title-desc">{{ this.metamask.default }}</p>
            </div>
            <div v-if="table.metamask.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.metamask.empty">
              {{ t("No records found.") }}
            </div>
            <div v-else class="table-responsive">
              <vcl-list
                v-if="table.metamask.loading"
                class="col-lg-6"
              ></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.metamask.heade" :key="th">
                      {{ t(th) }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.metamask.body" :key="index">
                    <td v-for="data in td" :key="data">{{ data }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="metamask.modal" :title="t('New Address')" centered>
      <b-form @submit.prevent="metamaskSubmit">
        <div
          v-if="alert.metamaskModal.message"
          :class="'alert ' + alert.metamaskModal.type"
        >
          {{ t(alert.metamaskModal.message) }}
        </div>
        <b-form-group
          id="metamask-address"
          :label="t('Address')"
          label-for="metamask-address"
        >
          <b-form-input
            id="metamask-address"
            v-model="metamask.address"
            type="text"
            :class="{ 'is-invalid': $v.metamask.address.$error }"
            autocomplete="off"
          ></b-form-input>
          <div v-if="$v.metamask.address.$error" class="invalid-feedback">
            <span v-if="!$v.metamask.address.required">{{
              t("Address is required.")
            }}</span>
          </div>
        </b-form-group>
        <b-button
          :disabled="!this.metamask.address"
          type="submit"
          variant="default"
          >{{ t("Send") }}</b-button
        >
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>
